import axios from 'axios'

export default {
  methods: {
    async loginToRouter(username, password, routerLoginLink) {
      const redirectUrl = `${routerLoginLink}?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
      await this.logRedirectUrl(redirectUrl)
      window.location.href = redirectUrl
    },
    logRedirectUrl(redirectUrl) {
      console.log('🚀 ~ logRedirectUrl ~ redirectUrl:', redirectUrl)

      return axios.post('logs', {
        message: `Redirect URL: ${redirectUrl}`,
      })
    },
  },
}
